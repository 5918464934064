<template>
  <RuesBoxSize :size="props.item?.width">
    <SharedCard>
      <template #img>
        <DelayedComponent :delay="3000">
          <img
            v-if="props.item?.type == 'mietwagen'"
            loading="lazy"
            src="https://img.travanto.de/travanto/teaser/teaser_sylt_mietwagen.png"
            alt="Mietwagen"
            height="auto"
            width="auto"
            :class="[ 'aspect-[16/9] object-cover object-center w-full fade-in' ]"
          >
          <template #preview>
            <div :class="[ 'aspect-[16/9] object-cover object-center w-full' ]" />
          </template>
        </DelayedComponent>
      </template>
      <template #text>
        <div class="grid gap-3 h-full">
          <div class="text-lg font-semibold">
            <UiLanguagePlaceholder domain="RuesCardMietwagen" name="headline" />
          </div>
          <div class="text-sm">
            <UiLanguagePlaceholder domain="RuesCardMietwagen" name="text" />
          </div>
          <div class="flex gap-4 w-full mt-4 text-base self-end">
            <UiMaskedLink
              :href="props.item?.url || ''"
              :new-tab="true"
              class="w-full"
            >
              <UiButton color="primary-high-contrast" class="w-full">
                <UiLanguagePlaceholder domain="RuesCardMietwagen" name="button" />
              </UiButton>
            </UiMaskedLink>
          </div>
        </div>
      </template>
    </SharedCard>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
</script>
